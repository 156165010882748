//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import toArray from 'lodash/toArray';
import { mapActions } from 'vuex';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import sortBy from 'lodash/sortBy';
import { PageNames } from '../constants';

export default {
  name: 'CopiesModal',
  mixins: [commonCoreStrings],
  props: {
    uniqueId: {
      type: String,
      required: true,
    },
    sharedContentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      copies: [],
    };
  },
  created() {
    this.getCopies(this.sharedContentId).then(copies => {
      // transform the copies objects from Array<Object> to Array<Array>
      const arrayedCopies = copies.map(copy => {
        return toArray(copy);
      });
      this.copies = sortBy(arrayedCopies, copy => copy[copy.length - 1].id !== this.uniqueId);
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(['getCopies']),
    generateCopyLink(id) {
      return {
        name: PageNames.TOPICS_CONTENT,
        params: { id },
        query: {
          searchTerm: this.$route.query.searchTerm || '',
        },
      };
    },
  },
  $trs: {
    copies: 'Locations',
  },
};

