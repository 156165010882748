//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapGetters } from 'vuex';
import AuthMessage from 'kolibri.coreVue.components.AuthMessage';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import ContentCard from '../ContentCard';
import commonLearnStrings from '../commonLearnStrings';
import { classAssignmentsLink } from './classPageLinks';

export default {
  name: 'AllClassesPage',
  metaInfo() {
    return {
      title: this.coreString('classesLabel'),
    };
  },
  components: {
    AuthMessage,
    ContentCard,
  },
  mixins: [commonCoreStrings, responsiveWindowMixin, commonLearnStrings],
  computed: {
    ...mapGetters(['isUserLoggedIn']),
    ...mapState('classes', ['classrooms']),
    CLASSROOM() {
      return ContentNodeKinds.CLASSROOM;
    },
  },
  methods: {
    classAssignmentsLink,
  },
  $trs: {
    noClasses: 'You are not enrolled in any classes',
  },
};

