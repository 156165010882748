//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import ContentCard from '../ContentCard';
import { lessonPlaylistLink } from './classPageLinks';

export default {
  name: 'AssignedLessonsCards',
  components: {
    ContentCard,
  },
  mixins: [commonCoreStrings],
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    LESSON: () => ContentNodeKinds.LESSON,
  },
  methods: {
    getLessonProgress(lesson) {
      const { resource_progress, total_resources } = lesson.progress;
      if (total_resources === 0) return undefined;
      return resource_progress / total_resources;
    },
    lessonPlaylistLink,
  },
  $trs: {
    noLessonsMessage: 'You have no lessons assigned',
  },
};

