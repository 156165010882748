//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import { PageNames } from '../constants';
import PageHeader from './PageHeader';
import ChannelCardGroupGrid from './ChannelCardGroupGrid';

export default {
  name: 'ChannelsPage',
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    PageHeader,
    ChannelCardGroupGrid,
  },
  mixins: [commonCoreStrings],
  computed: {
    ...mapState('topicsRoot', { channels: 'rootNodes' }),
  },
  methods: {
    genChannelLink(channel_id) {
      return {
        name: PageNames.TOPICS_CHANNEL,
        params: { channel_id },
      };
    },
  },
  $trs: {
    documentTitle: 'All channels',
  },
};

