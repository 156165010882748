//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';

export default {
  name: 'PageHeader',
  components: {
    ProgressIcon,
  },
  props: {
    title: {
      type: String,
    },
    progress: {
      type: Number,
      required: false,
    },
    contentType: {
      type: String,
    },
  },
};

