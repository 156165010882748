//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapState } from 'vuex';
import Navbar from 'kolibri.coreVue.components.Navbar';
import NavbarLink from 'kolibri.coreVue.components.NavbarLink';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import { ClassesPageNames, PageNames } from '../constants';
import commonLearnStrings from './commonLearnStrings';

export default {
  name: 'LearnTopNav',
  components: {
    Navbar,
    NavbarLink,
  },
  mixins: [commonCoreStrings, commonLearnStrings],
  data() {
    return {
      allClassesLink: {
        name: ClassesPageNames.ALL_CLASSES,
      },
      channelsLink: {
        name: PageNames.TOPICS_ROOT,
      },
      recommendedLink: {
        name: PageNames.RECOMMENDED,
      },
    };
  },
  computed: {
    ...mapGetters(['isUserLoggedIn', 'canAccessUnassignedContent']),
    ...mapState({
      userHasMemberships: state => state.memberships.length > 0,
    }),
    showClassesLink() {
      return this.isUserLoggedIn && (this.userHasMemberships || !this.canAccessUnassignedContent);
    },
  },
};

