//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'AnswerIcon',
  props: {
    answer: {
      type: String,
      required: true,
      validator(val) {
        return ['right', 'wrong', 'hint', 'rectified'].includes(val);
      },
    },
  },
  computed: {
    tooltipText() {
      switch (this.answer) {
        case 'right':
          return this.$tr('correct');
        case 'wrong':
          return this.$tr('incorrect');
        case 'hint':
          return this.$tr('hintUsed');
        case 'rectified':
          return this.$tr('incorrectFirstTry');
        default:
          return '';
      }
    },
  },
  $trs: {
    correct: 'Correct',
    incorrect: 'Incorrect',
    hintUsed: 'Hint used',
    incorrectFirstTry: 'Incorrect first try',
  },
};

