//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'Snackbar',
  mixins: [commonCoreStrings],
};

