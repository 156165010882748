//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';

function isAboveContainer(element, container) {
  return element.offsetTop < container.scrollTop;
}

function isBelowContainer(element, container) {
  return element.offsetTop + element.offsetHeight > container.offsetHeight + container.scrollTop;
}

export default {
  name: 'AnswerHistory',
  props: {
    questionNumber: {
      type: Number,
      required: true,
    },
    // hack to get access to the scrolling pane
    wrapperComponentRefs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('examViewer', ['questions']),
    ...mapState({ attemptLogs: 'examAttemptLogs' }),
  },
  watch: {
    questionNumber(index) {
      // If possible, scroll it into view
      const element = this.$refs[`item-${index}`][0];
      if (element && element.scrollIntoView && this.wrapperComponentRefs.questionListWrapper) {
        const container = this.wrapperComponentRefs.questionListWrapper.$el;
        if (isAboveContainer(element, container)) {
          element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
        } else if (isBelowContainer(element, container)) {
          element.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
        }
      }
    },
  },
  methods: {
    questionText(num) {
      return this.$tr('question', { num });
    },
    isAnswered(question) {
      return ((this.attemptLogs[question.exercise_id] || {})[question.question_id] || {}).answer;
    },
    buttonClass(index) {
      if (this.questionNumber === index) {
        return this.$computedClass({ backgroundColor: this.$themePalette.grey.v_200 });
      }
      return this.$computedClass({
        backgroundColor: this.$themeTokens.surface,
        ':hover': {
          backgroundColor: this.$themePalette.grey.v_100,
        },
      });
    },
  },
  $trs: {
    question: 'Question { num }',
  },
};

