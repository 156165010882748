//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import PointsIcon from 'kolibri.coreVue.components.PointsIcon';

export default {
  name: 'TotalPoints',
  components: {
    PointsIcon,
  },
  mixins: [responsiveWindowMixin],
  computed: {
    ...mapGetters(['totalPoints', 'currentUserId', 'isUserLoggedIn']),
  },
  watch: {
    currentUserId() {
      this.fetchPoints();
    },
  },
  created() {
    this.fetchPoints();
  },
  methods: {
    ...mapActions(['fetchPoints']),
  },
  $trs: { pointsTooltip: 'You earned { points, number } points' },
};

