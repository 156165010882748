//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { validateLinkObject } from 'kolibri.utils.validators';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import ContentCard from './ContentCard';
import CopiesModal from './CopiesModal';

export default {
  name: 'ContentCardGroupGrid',
  components: {
    ContentCard,
    CopiesModal,
  },
  mixins: [responsiveWindowMixin],
  props: {
    contents: {
      type: Array,
      required: true,
    },
    genContentLink: {
      type: Function,
      validator(value) {
        return validateLinkObject(value(1, 'exercise'));
      },
      default: () => {},
      required: false,
    },
  },
  data: () => ({
    modalIsOpen: false,
    sharedContentId: null,
    uniqueId: null,
  }),
  methods: {
    openCopiesModal(contentId) {
      this.sharedContentId = contentId;
      this.uniqueId = this.contents.find(content => content.content_id === contentId).id;
      this.modalIsOpen = true;
    },
  },
};

