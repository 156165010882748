//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import { MaxPointsPerContent, ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import PointsIcon from 'kolibri.coreVue.components.PointsIcon';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import ContentIcon from 'kolibri.coreVue.components.ContentIcon';
import UiAlert from 'kolibri-design-system/lib/keen/UiAlert';
import Snackbar from './Snackbar';

const SNACKBARS = { POINTS: 'POINTS', NEXT_RESOURCE: 'NEXT_RESOURCE' };

export default {
  name: 'MasteredSnackbars',
  components: {
    PointsIcon,
    ProgressIcon,
    ContentIcon,
    Snackbar,
    UiAlert,
  },
  props: {
    nextContent: {
      type: Object,
      required: false,
    },
    nextContentLink: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    currentSnackbar: null,
    pointsTimeout: null,
    nextContentTimeout: null,
  }),

  computed: {
    ...mapGetters(['isUserLoggedIn']),
    SNACKBARS() {
      return SNACKBARS;
    },
    maxPoints() {
      return MaxPointsPerContent;
    },
    iconBackgroundColor() {
      switch (this.nextContent.kind) {
        case ContentNodeKinds.EXERCISE:
          return this.$themeTokens.exercise;
        case ContentNodeKinds.VIDEO:
          return this.$themeTokens.video;
        case ContentNodeKinds.AUDIO:
          return this.$themeTokens.audio;
        case ContentNodeKinds.DOCUMENT:
          return this.$themeTokens.document;
        case ContentNodeKinds.HTML5:
          return this.$themeTokens.html5;
        default:
          return this.$themeTokens.topic;
      }
    },
  },
  watch: {
    currentSnackbar(val) {
      if (val === SNACKBARS.NEXT_RESOURCE) {
        this.nextContentTimeout = setTimeout(() => {
          this.currentSnackbar = null;
        }, 10000);
      }
    },
  },
  mounted() {
    this.currentSnackbar = SNACKBARS.POINTS;
    this.pointsTimeout = setTimeout(() => {
      if (this.currentSnackbar === SNACKBARS.POINTS) {
        this.currentSnackbar = SNACKBARS.NEXT_RESOURCE;
      }
    }, 4000);
  },
  beforeDestroy() {
    if (this.pointsTimeout) {
      clearTimeout(this.pointsTimeout);
    }
    if (this.nextContentTimeout) {
      clearTimeout(this.nextContentTimeout);
    }
  },
  $trs: {
    plusPoints: '+ { maxPoints, number } Points',
    next: 'Next:',
    signIn: 'Sign in or create an account to begin earning points',
  },
};

