//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PrivacyInfoModal from 'kolibri.coreVue.components.PrivacyInfoModal';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'UpdateYourProfileModal',
  components: {
    PrivacyInfoModal,
  },
  mixins: [commonCoreStrings],
  data() {
    return {
      privacyModalVisible: false,
    };
  },
  $trs: {
    title: 'Update your profile',
    updateProfileExplanation:
      'Some information is missing from your profile. Would you like to update it?',
    skipUpdateProfileAction: 'Skip',
  },
};

