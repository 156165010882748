//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import { validateLinkObject, validateContentNodeKind } from 'kolibri.utils.validators';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import CoachContentLabel from 'kolibri.coreVue.components.CoachContentLabel';
import TextTruncator from 'kolibri.coreVue.components.TextTruncator';
import CardThumbnail from './CardThumbnail';

export default {
  name: 'ContentCard',
  components: {
    CardThumbnail,
    CoachContentLabel,
    TextTruncator,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      required: false,
    },
    kind: {
      type: String,
      required: true,
      validator: validateContentNodeKind,
    },
    showContentIcon: {
      type: Boolean,
      default: true,
    },
    // ContentNode.coach_content will be `0` if not a coach content leaf node,
    // or a topic without coach content. It will be a positive integer if a topic
    // with coach content, and `1` if a coach content leaf node.
    numCoachContents: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      required: false,
      default: 0.0,
      validator(value) {
        return value >= 0.0 && value <= 1.0;
      },
    },
    link: {
      type: Object,
      required: true,
      validator: validateLinkObject,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    contentId: {
      type: String,
      required: false,
    },
    copiesCount: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['isLearner', 'isUserLoggedIn']),
    isTopic() {
      return this.kind === ContentNodeKinds.TOPIC || this.kind === ContentNodeKinds.CHANNEL;
    },
    maxTitleHeight() {
      if (this.hasFooter && this.subtitle) {
        return 20;
      } else if (this.hasFooter || this.subtitle) {
        return 40;
      }
      return 60;
    },
    hasFooter() {
      return this.numCoachContents > 0 || this.copiesCount > 1;
    },
  },
  $trs: {
    copies: '{ num, number} locations',
  },
};

