//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import uniq from 'lodash/uniq';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import { ContentNodeProgressResource } from 'kolibri.resources';
import { PageNames } from '../constants';
import commonLearnStrings from './commonLearnStrings';
import ContentCardGroupCarousel from './ContentCardGroupCarousel';
import ContentCardGroupGrid from './ContentCardGroupGrid';
import ContentCardGroupHeader from './ContentCardGroupHeader';
import learnIndexStrings from './learnIndexStrings';

const mobileCarouselLimit = 3;
const desktopCarouselLimit = 15;

export default {
  name: 'RecommendedPage',
  metaInfo() {
    return {
      title: this.learnString('learnLabel'),
    };
  },
  components: {
    ContentCardGroupCarousel,
    ContentCardGroupGrid,
    ContentCardGroupHeader,
  },
  mixins: [commonLearnStrings, responsiveWindowMixin, learnIndexStrings],
  computed: {
    ...mapState('recommended', ['nextSteps', 'popular', 'resume']),
    carouselLimit() {
      return this.windowIsSmall ? mobileCarouselLimit : desktopCarouselLimit;
    },
    popularPageLink() {
      return {
        name: PageNames.RECOMMENDED_POPULAR,
      };
    },
    nextStepsPageLink() {
      return {
        name: PageNames.RECOMMENDED_NEXT_STEPS,
      };
    },
    resumePageLink() {
      return {
        name: PageNames.RECOMMENDED_RESUME,
      };
    },
    trimmedPopular() {
      return this.popular.slice(0, this.carouselLimit);
    },
    trimmedNextSteps() {
      return this.nextSteps.slice(0, this.carouselLimit);
    },
    trimmedResume() {
      return this.resume.slice(0, this.carouselLimit);
    },
  },
  created() {
    if (this.$store.getters.isUserLoggedIn) {
      const contentNodeIds = uniq(
        [...this.trimmedNextSteps, ...this.trimmedPopular, ...this.trimmedResume].map(
          ({ id }) => id
        )
      );

      if (contentNodeIds.length > 0) {
        ContentNodeProgressResource.fetchCollection({ getParams: { ids: contentNodeIds } }).then(
          progresses => {
            this.$store.commit('recommended/SET_RECOMMENDED_NODES_PROGRESS', progresses);
          }
        );
      }
    }
  },
  methods: {
    genContentLink(id, kind) {
      return {
        name: kind === ContentNodeKinds.TOPIC ? PageNames.TOPICS_TOPIC : PageNames.TOPICS_CONTENT,
        params: { id },
        query: {
          last: this.$store.state.pageName,
        },
      };
    },
  },
  $trs: {
    popularSectionHeader: 'Most popular',
    suggestedNextStepsSectionHeader: 'Next steps',
  },
};

