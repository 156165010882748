//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import { PageNames } from '../constants';
import ContentCardGroupGrid from './ContentCardGroupGrid';
import CardThumbnail from './ContentCard/CardThumbnail';

export default {
  name: 'TopicsPage',
  metaInfo() {
    let title;
    if (this.isRoot) {
      title = this.$tr('documentTitleForChannel', {
        channelTitle: this.channelTitle,
      });
    } else {
      title = this.$tr('documentTitleForTopic', {
        channelTitle: this.channelTitle,
        topicTitle: this.topic.title,
      });
    }
    return { title };
  },
  components: {
    CardThumbnail,
    ContentCardGroupGrid,
    ProgressIcon,
  },
  mixins: [responsiveWindowMixin],
  computed: {
    ...mapState('topicsTree', ['channel', 'contents', 'isRoot', 'topic']),
    channelTitle() {
      return this.channel.title;
    },
    topicOrChannel() {
      // Get the channel if we're root, topic if not
      return this.isRoot ? this.channel : this.topic;
    },
    getTagline() {
      return this.topicOrChannel['tagline'] || this.topicOrChannel['description'] || null;
    },
    calculateProgress() {
      // calculate progress across all topics
      const contentsLength = this.contents.length;
      if (contentsLength !== 0) {
        const computedSum =
          this.contents.map(content => content.progress).reduce((acc, val) => acc + val) /
          contentsLength;
        return computedSum !== 0 ? computedSum : undefined;
      }

      return undefined;
    },
  },
  methods: {
    genContentLink(id, kind) {
      const routeName =
        kind === ContentNodeKinds.TOPIC ? PageNames.TOPICS_TOPIC : PageNames.TOPICS_CONTENT;
      return {
        name: routeName,
        params: { id },
      };
    },
  },
  $trs: {
    documentTitleForChannel: 'Topics - { channelTitle }',
    documentTitleForTopic: '{ topicTitle } - { channelTitle }',
  },
};

