//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import SearchBox from './SearchBox';

export default {
  name: 'ActionBarSearchBox',
  components: {
    SearchBox,
  },
  mixins: [responsiveWindowMixin],
  data() {
    return {
      searchBoxIsOpen: false,
    };
  },
  computed: {
    searchBoxIsDropdown() {
      return this.windowIsSmall;
    },
    searchBoxIsVisible() {
      if (this.searchBoxIsDropdown) {
        return this.searchBoxIsOpen;
      }
      return true;
    },
  },
  created() {
    window.addEventListener('click', this.handleClick);
    window.addEventListener('focusin', this.handleFocusIn);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick);
    window.removeEventListener('focusin', this.handleFocusIn);
  },
  methods: {
    focusOnSearchBox() {
      this.$nextTick(() => {
        this.$refs.searchBox.$refs.searchInput.focus();
      });
    },
    openDropdownSearchBox() {
      this.searchBoxIsOpen = true;
      this.focusOnSearchBox();
    },
    closeDropdownSearchBox() {
      this.searchBoxIsOpen = false;
    },
    toggleDropdownSearchBox() {
      if (this.searchBoxIsOpen) {
        this.closeDropdownSearchBox();
      } else {
        this.openDropdownSearchBox();
      }
    },
    handleClick(event) {
      if (this.searchBoxIsOpen && !this.$refs.toggleBtnAndSearchBox.contains(event.target)) {
        this.closeDropdownSearchBox();
      }
    },
    handleFocusIn(event) {
      if (this.searchBoxIsOpen && !this.$refs.toggleBtnAndSearchBox.contains(event.target)) {
        this.closeDropdownSearchBox();
      }
    },
  },
};

