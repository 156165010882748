//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import sumBy from 'lodash/sumBy';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import ContentIcon from 'kolibri.coreVue.components.ContentIcon';
import { getContentNodeThumbnail } from 'kolibri.utils.contentNode';
import ContentCard from '../ContentCard';
import { lessonResourceViewerLink } from './classPageLinks';

export default {
  name: 'LessonPlaylistPage',
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    ContentCard,
    ContentIcon,
    ProgressIcon,
  },
  computed: {
    ...mapState('lessonPlaylist', ['contentNodes', 'currentLesson']),
    lessonHasResources() {
      return this.contentNodes.length > 0;
    },
    lessonProgress() {
      if (this.lessonHasResources) {
        // HACK: Infer the Learner's progress by summing the progress_fractions
        // on all the ContentNodes
        const total = sumBy(this.contentNodes, cn => cn.progress_fraction || 0);
        if (total === 0) {
          return null;
        }
        return total / this.contentNodes.length;
      }

      return undefined;
    },
  },
  methods: {
    getContentNodeThumbnail,
    lessonResourceViewerLink,
  },
  $trs: {
    noResourcesInLesson: 'There are no resources in this lesson',
    teacherNote: 'Coach note',
    documentTitle: 'Lesson contents',
  },
};

