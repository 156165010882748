//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ContentIcon from 'kolibri.coreVue.components.ContentIcon';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import { validateContentNodeKind } from 'kolibri.utils.validators';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';

export default {
  name: 'CardThumbnail',
  components: {
    ContentIcon,
    ProgressIcon,
  },
  props: {
    thumbnail: {
      type: String,
      required: false,
    },
    kind: {
      type: String,
      required: true,
      validator: validateContentNodeKind,
    },
    // If true, shows the content icon on the upper left of the thumbnail
    showContentIcon: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      required: false,
      default: 0.0,
      validator(value) {
        return value >= 0.0 && value <= 1.0;
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMastered() {
      return this.progress === 1;
    },
    isInProgress() {
      return this.progress > 0 && this.progress < 1;
    },
    thumbnailBackground() {
      return {
        backgroundColor: this.$themeTokens.surface,
        backgroundImage: this.thumbnail ? `url('${this.thumbnail}')` : '',
      };
    },
    contentIconBgCoords() {
      const topLeft = '0,0';
      const topRight = '64,0';
      const bottomLeft = '0,64';
      const bottomRight = '64,64';
      if (this.isRtl) {
        return `${topLeft} ${topRight} ${bottomRight}`;
      }
      return `${topLeft} ${topRight} ${bottomLeft}`;
    },
    contentIconBgColor() {
      switch (this.kind) {
        case ContentNodeKinds.EXERCISE:
          return { fill: this.$themeTokens.exercise };
        case ContentNodeKinds.VIDEO:
          return { fill: this.$themeTokens.video };
        case ContentNodeKinds.AUDIO:
          return { fill: this.$themeTokens.audio };
        case ContentNodeKinds.DOCUMENT:
          return { fill: this.$themeTokens.document };
        case ContentNodeKinds.HTML5:
          return { fill: this.$themeTokens.html5 };
        case ContentNodeKinds.SLIDESHOW:
          return { fill: this.$themeTokens.slideshow };
        default:
          return { fill: this.$themeTokens.topic };
      }
    },
  },
};

