//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import { ContentNodeProgressResource } from 'kolibri.resources';
import { PageNames } from '../constants';
import ContentCardGroupGrid from './ContentCardGroupGrid';
import commonLearnStrings from './commonLearnStrings';
import learnIndexStrings from './learnIndexStrings';

export default {
  name: 'RecommendedSubpage',
  metaInfo() {
    return {
      title: this.documentTitle,
    };
  },
  components: {
    ContentCardGroupGrid,
  },
  mixins: [commonLearnStrings, learnIndexStrings],
  computed: {
    ...mapState(['pageName']),
    ...mapState('recommended', ['nextSteps', 'popular', 'resume']),
    documentTitle() {
      switch (this.pageName) {
        case PageNames.RECOMMENDED_POPULAR:
          return this.$tr('documentTitleForPopular');
        case PageNames.RECOMMENDED_RESUME:
          return this.learnIndexString('documentTitleForResume');
        case PageNames.RECOMMENDED_NEXT_STEPS:
          return this.$tr('documentTitleForNextSteps');
        default:
          return '';
      }
    },
    header() {
      switch (this.pageName) {
        case PageNames.RECOMMENDED_POPULAR:
          return this.$tr('popularPageHeader');
        case PageNames.RECOMMENDED_RESUME:
          return this.learnIndexString('documentTitleForResume');
        case PageNames.RECOMMENDED_NEXT_STEPS:
          return this.$tr('nextStepsPageHeader');
        default:
          return null;
      }
    },
    breadcrumbItems() {
      return [
        {
          text: this.learnString('recommendedLabel'),
          link: this.$router.getRoute(PageNames.RECOMMENDED),
        },
        {
          text: this.header,
        },
      ];
    },
    recommendations() {
      switch (this.pageName) {
        case PageNames.RECOMMENDED_POPULAR:
          return this.popular;
        case PageNames.RECOMMENDED_RESUME:
          return this.resume;
        case PageNames.RECOMMENDED_NEXT_STEPS:
          return this.nextSteps;
        default:
          return [];
      }
    },
  },
  created() {
    if (this.$store.getters.isUserLoggedIn) {
      if (this.recommendations.length > 0) {
        for (let i = 0; i < this.recommendations.length; i += 50) {
          ContentNodeProgressResource.fetchCollection({
            getParams: { ids: this.recommendations.slice(i, i + 50).map(({ id }) => id) },
          }).then(progresses => {
            this.$store.commit('recommended/SET_RECOMMENDED_NODES_PROGRESS', progresses);
          });
        }
      }
    }
  },
  methods: {
    genContentLink(id, kind) {
      const pageName =
        kind === ContentNodeKinds.TOPIC ? PageNames.TOPICS_TOPIC : PageNames.TOPICS_CONTENT;
      return this.$router.getRoute(pageName, { id }, { last: this.pageName });
    },
  },
  $trs: {
    popularPageHeader: 'Most popular',
    nextStepsPageHeader: 'Next steps',
    documentTitleForPopular: 'Popular',
    documentTitleForNextSteps: 'Next Steps',
  },
};

