//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AnswerIcon from './AnswerIcon';

export default {
  name: 'ExerciseAttempts',
  components: { AnswerIcon },
  props: {
    // Creates an empty space awaiting a new attempt
    waitingForAttempt: {
      type: Boolean,
      required: true,
    },
    // Total number of answer spaces to show
    numSpaces: {
      type: Number,
      required: true,
    },
    // Array of answers - strings that are 'right', 'wrong', or 'hint'
    // ordered from first to last
    log: {
      type: Array,
      validator(arr) {
        return arr.every(val => ['right', 'wrong', 'hint', 'rectified'].includes(val));
      },
    },
  },
  computed: {
    numItemsToRender() {
      if (this.waitingForAttempt) {
        return this.numSpaces;
      }
      return this.numSpaces + 1;
    },
    // returns a list of items the items to be rendered in the DOM
    itemsToRender() {
      // save the original index of the item in the log and slice of the end
      return this.log
        .map((answer, originalIndex) => ({
          answer,
          originalIndex,
        }))
        .slice(-1 * this.numItemsToRender)
        .reverse();
    },
  },
  methods: {
    styleForIndex(visualIndex, originalIndex) {
      const ANSWER_WIDTH = 4 + 30 + 4;
      let xPos = ANSWER_WIDTH * (this.log.length - 1 - originalIndex);
      if (this.waitingForAttempt) {
        xPos += ANSWER_WIDTH;
      }
      const style = {};
      const side = this.isRtl ? 'right' : 'left';
      style[side] = `${xPos}px`;
      // hidden "slide-off" item
      if (visualIndex === this.numItemsToRender - 1) {
        style.opacity = 0;
      }
      return style;
    },
  },
};

